<template>
  <div class="MobileRow__main-container">
    <div
      v-for="(data, index) in rowData"
      :key="data.title + index"
      class="MobileRow__row"
    >
      <div class="MobileRow__title">{{ data.title }}</div>
      <div v-if="data.text" class="MobileRow__text">{{ data.text }}</div>
      <img
        v-if="data.src === 'nigeria'"
        src="@/assets/svg/nigeria_icon.svg"
        class="MobileRow__img"
      />
      <img
        v-else-if="data.src === 'intl'"
        src="@/assets/svg/intl_icon.svg"
        class="MobileRow__img"
      />
      <slot v-if="data.slot" :name="data.slot"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileRow',

  props: {
    rowData: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/utils/MobileRow.scss';
</style>
